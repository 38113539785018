import React, { useState, useEffect } from 'react';
import './AnnamPage.css';
import corevaluesImg from '../../assets/annampage-images/core-values.png';
import { FaYoutube, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { annamPageData } from '../../DATA'; // Adjust the import path as needed

const AnnamPage = ({ isFixed = true }) => {
    const navigate = useNavigate();

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        if (!isFixed) return;

        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isFixed]);

    const logo = "YUKTII VENTURES";

    const renderLogo = () => {
        const [firstWord, secondWord] = logo.split(' ');
        return (
            <>
                <div className="p-logo-text">
                    {firstWord.split('').map((char, index) =>
                        char.toUpperCase() === 'U' ?
                            <span key={index} className="p-orange-u">{char}</span> :
                            char
                    )}
                </div>
                <div className="p-logo-subtext">{secondWord}</div>
            </>
        );
    };

    return (
        <div className="container">
            {/* Navbar */}
            <nav className={`a-navbar ${isFixed && isScrolled ? 'a-navbar-scrolled' : ''} ${isFixed ? 'a-navbar-fixed' : ''}`}>
                <a href="/" className="a-navbar-logo">
                    {renderLogo()}
                </a>
                <div className="a-navbar-center">
                    ANNAM
                </div>
                <div className="a-nav-links">
                    {annamPageData.navbar.links.map((link, index) => (
                        <a key={index} href={link.url} >
                            {link.text}
                        </a>
                    ))}
                </div>
            </nav>

            {/* Landing Page Section */}
            <section className="landing-section">
                <div className="landing-content">
                    <h1 className="landing-title">{annamPageData.heroSection.title}</h1>
                    <p className="landing-text">
                        {annamPageData.heroSection.description}
                    </p>
                    {/* <button
                        className="button"
                        onClick={() => window.location.href = annamPageData.heroSection.ctaLink}
                    >
                        {annamPageData.heroSection.ctaText}
                    </button> */}
                </div>
            </section>




            <div id='projects' className="projects-section">
                <h1 className="projects-title">{annamPageData.projectsSection.header.title}</h1>
                <div
                    className="bhojanam-card"
                    onClick={() => navigate('/annam/bhojanam')}
                >
                    <h2 className="bhojanam-title">{annamPageData.projectsSection.project.title}</h2>
                    <p className="bhojanam-description">
                        {annamPageData.projectsSection.project.overview}
                    </p>
                </div>
            </div>

            {/* Keywords Section - Added After CTA Button */}
            <div className="keywords-section">
                <h3 className="keywords-title">Key Focus Areas</h3>
                <div className="keywords-container">
                    {annamPageData.heroSection.keywords.map((keyword, index) => (
                        <span key={index} className="keyword-tag">
                            {keyword}
                        </span>
                    ))}
                </div>
            </div>

            {/* About Section */}
            <section id='about' className="about-section">
                <h2 className="about-title">{annamPageData.aboutSection.header.title}</h2>
                <div className="about-grid">
                    <div className="about-card">
                        <h3 className="card-title">{annamPageData.aboutSection.mission.title}</h3>
                        <p className="card-text">{annamPageData.aboutSection.mission.description}</p>
                    </div>
                    <div className="about-card">
                        <h3 className="card-title">{annamPageData.aboutSection.vision.title}</h3>
                        <p className="card-text">{annamPageData.aboutSection.vision.description}</p>
                    </div>
                    <div className="about-card">
                        <h3 className="card-title">Our Approach</h3>
                        <p className="card-text">
                            ANNAM combines cutting-edge AI technology with deep expertise in food systems to create innovative solutions. We work closely with farmers, food producers, distributors, and consumers to develop tools and strategies that optimize resource use, reduce waste, and promote sustainable practices throughout the entire food value chain.
                        </p>
                    </div>
                </div>
            </section>

            <section className='core-values'>
                <h1 className="core-values-title">{annamPageData.aboutSection.coreValues.title}</h1>
                <img src={corevaluesImg} alt="core-values" />
            </section>


            <footer className="p-footer">
                <div className="p-footer-content">
                    <p>{annamPageData.footer.copyright}</p>
                    <div className="p-social-icons">
                        <a href="https://www.linkedin.com/company/yuktii-ventures/" aria-label="LinkedIn" target="_blank"><FaLinkedin size={32} /></a>
                        <a href="https://x.com/Yuktii_Ventures" aria-label="Twitter" target="_blank"><FaXTwitter size={32} /></a>
                        <a href="https://youtube.com/@yuktii_ventures" aria-label="Youtube" target="_blank"><FaYoutube size={32} /></a>
                        <a href="https://www.instagram.com/yuktii.ventures" aria-label="Instagram" target="_blank"><FaInstagram size={32} /></a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default AnnamPage;