import React, { useState, useEffect } from 'react';
import { FaYoutube, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { chakraPageData } from '../../DATA';
import './ChakraPage.css';


const ChakraPage = ({ isFixed = true }) => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        if (!isFixed) return;

        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isFixed]);

    const logo = "YUKTII VENTURES";

    const renderLogo = () => {
        const [firstWord, secondWord] = logo.split(' ');
        return (
            <>
                <div className="p-logo-text">
                    {firstWord.split('').map((char, index) =>
                        char.toUpperCase() === 'U' ?
                            <span key={index} className="p-orange-u">{char}</span> :
                            char
                    )}
                </div>
                <div className="p-logo-subtext">{secondWord}</div>
            </>
        );
    };

    return (
        <div className="c-container">

            <nav className={`c-navbar ${isFixed && isScrolled ? 'c-navbar-scrolled' : ''} ${isFixed ? 'c-navbar-fixed' : ''}`}>
                <a href="/" className="c-navbar-logo">
                    {renderLogo()}
                </a>
                <div className="c-navbar-center">
                    CHAKRA
                </div>
                <div className="c-nav-links">
                    {chakraPageData.navbar.links.map((link, index) => (
                        <a key={index} href={link.url} >
                            {link.text}
                        </a>
                    ))}
                </div>
            </nav>

            {/* Hero Section */}
            <div className="c-hero">
                <h1>{chakraPageData.heroSection.title}</h1>
                <p>{chakraPageData.heroSection.description}</p>
                <button onClick={() => window.location.href = chakraPageData.heroSection.ctaLink}>
                    {chakraPageData.heroSection.ctaText}
                </button>
            </div>

            {/* About Section */}
            <section id="about" className="c-about-section">
                <div className="c-about-container">
                    <div className="c-about-header">
                        <h2>{chakraPageData.aboutSection.header.title}</h2>
                        {chakraPageData.aboutSection.header.description.map((paragraph, index) => (
                            <p key={index}>{paragraph}</p>
                        ))}
                    </div>

                    <div className="c-mission-vision">
                        <div className="c-mission">
                            <h3>{chakraPageData.aboutSection.mission.title}</h3>
                            <p>{chakraPageData.aboutSection.mission.description}</p>
                        </div>
                        <div className="c-vision">
                            <h3>{chakraPageData.aboutSection.vision.title}</h3>
                            <p>{chakraPageData.aboutSection.vision.description}</p>
                        </div>
                    </div>

                    <div className="c-core-values">
                        <h3>{chakraPageData.aboutSection.coreValues.title}</h3>
                        <div className="c-values-quadrant">
                            {chakraPageData.aboutSection.coreValues.items.map((value, index) => (
                                <div key={index} className="c-value-item">
                                    <h4>{value.title}</h4>
                                    <p>{value.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* Projects Section */}
            <section id="projects" className="c-projects-section">
                <div className="c-projects-container">
                    <div className="c-projects-header">
                        <h2>{chakraPageData.projectsSection.header.title}</h2>
                    </div>

                    <div className="c-project-content">
                        <div className="c-project-intro">
                            <h3>{chakraPageData.projectsSection.project.title}</h3>
                            <p className="c-project-tagline">{chakraPageData.projectsSection.project.tagline}</p>
                        </div>

                        <div className="c-project-overview">
                            <p>{chakraPageData.projectsSection.project.overview}</p>
                        </div>

                        {/* Feature Grid */}
                        <div className="c-features-grid">
                            {chakraPageData.projectsSection.project.features.map((feature, index) => (
                                <div key={index} className="c-feature-column">
                                    <h4>{feature.title}</h4>
                                    <ul>
                                        {feature.items.map((item, itemIndex) => (
                                            <li key={itemIndex}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>

                        {/* Technical Capabilities */}
                        <div className="c-capabilities-section">
                            <h4>Technical Capabilities</h4>
                            <div className="c-capabilities-grid">
                                {chakraPageData.projectsSection.project.technicalCapabilities.map((capability, index) => (
                                    <div key={index} className="c-capability-card">
                                        <h5>{capability.title}</h5>
                                        <ul>
                                            {capability.items.map((item, itemIndex) => (
                                                <li key={itemIndex}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Environmental Impact */}
                        <div className="c-impact-workflow">
                            <div className="c-impact-section">
                                <h4>Environmental Impact</h4>
                                <div className="c-impact-grid">
                                    {chakraPageData.projectsSection.project.environmentalImpact.map((impact, index) => (
                                        <div key={index} className="c-impact-card">
                                            <h5>{impact.title}</h5>
                                            <ul>
                                                {impact.items.map((item, itemIndex) => (
                                                    <li key={itemIndex}>{item}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Workflow */}
                            <div className="c-workflow-section">
                                <h4>How It Works</h4>
                                <div className="c-workflow-steps">
                                    {chakraPageData.projectsSection.project.workflow.map((step, index) => (
                                        <div key={index} className="c-step">
                                            <h5>{step.step}</h5>
                                            <p>{step.description}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer */}
            {/* <footer className="p-footer">
                <div className="p-footer-content">
                    <p>{chakraPageData.footer.copyright}</p>
                    <div className="p-social-icons">
                        {chakraPageData.footer.socialLinks.map((link, index) => (
                            <a key={index} href={link.href} aria-label={link.label}>
                                <link.icon size={24} />
                            </a>
                        ))}
                    </div>
                </div>
            </footer> */}

            <footer className="c-footer">
                <div className="c-footer-content">
                    <p>CHAKRA @ 2024 - 2025. All rights reserved.</p>
                    <div className="c-social-icons">
                    <a href="https://www.linkedin.com/company/yuktii-ventures/" aria-label="LinkedIn" target="_blank"><FaLinkedin size={32} /></a>
                                <a href="https://x.com/Yuktii_Ventures" aria-label="Twitter" target="_blank"><FaXTwitter size={32} /></a>
                                <a href="https://youtube.com/@yuktii_ventures" aria-label="Youtube" target="_blank"><FaYoutube size={32} /></a>
                                <a href="https://www.instagram.com/yuktii.ventures" aria-label="Instagram" target="_blank"><FaInstagram size={32} /></a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default ChakraPage;
