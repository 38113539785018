import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import VenturesPage from './pages/VenturesPage/VenturesPage';
import AboutPage from './pages/AboutPage/AboutPage';
import PodcastPage from './pages/PodcastPage/PodcastPage';
import WritingsPage from './pages/WritingPage/WritingPage';
import ParinaamLandingPage from './VenturesLandingPages/ParinaamLandingPage/ParinaamLandingPage';
import ChakraPage from './VenturesLandingPages/ChakraLandingPage/ChakraPage';
import AnnamPage from './VenturesLandingPages/AnnamLandingPage/AnnamPage';
import BhojanamPage from './VenturesLandingPages/AnnamLandingPage/Bhojanam/bhojanamPage';
import TermsOfService from './components/Footer/TermsOfService';
import PrivacyPolicy from './components/Footer/PrivacyPolicy';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './globalStyles.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/ventures" element={<VenturesPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/podcast" element={<PodcastPage />} />
        <Route path="/writing" element={<WritingsPage />} />
        <Route path="/parinaam" element={<ParinaamLandingPage />} />
        <Route path="/chakra" element={<ChakraPage />} />
        <Route path="/annam" element={<AnnamPage />} />
        <Route path="/annam/bhojanam" element={<BhojanamPage />} />
        <Route path="/termsofservice" element={<TermsOfService />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
