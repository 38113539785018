import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import './LandingPage.css';
// import WhyChooseUs from '../../components/LandingPage Components/WhyChooseUs/WhyChooseUs';
// import ReviewSection from '../../components/LandingPage Components/ReviewSection/ReviewSection';
// import FAQ from '../../components/FAQ/FAQ';
import Footer from '../../components/Footer/Footer';
import { landingPageData } from '../../DATA'

const LandingPage = () => {
  const { hero, featureSection, companyStats, blogPosts } = landingPageData;

  return (
    <div className="landing-page">
      <Navbar />
      <div className="hero">
        <h1 className="page-title">{hero.title}</h1>
        <p>{hero.description}</p>
      </div>
      <div className="feature-section">
        <div className="feature-text">
          <h2>{featureSection.title}</h2>
          <p>{featureSection.description}</p>
        </div>
        <div className="feature-image">
          <img src={featureSection.imagePath} alt="Empowering Future through Technology" />
        </div>
      </div>
      {/* <WhyChooseUs />
      <div className="company-stats">
        {companyStats.map((stat, index) => (
          <div key={index} className="stat-item">
            <h3>{stat.number}</h3>
            <p>{stat.label}</p>
          </div>
        ))}
      </div>
      <ReviewSection /> */}
      <div className="blog-section">
        <h2>Latest From Our Social Media</h2>
        <div className="blog-posts">
          {blogPosts.map((post, index) => (
            <div key={index} className="blog-post">
              <div className="blog-image">
                <img src={post.image}
                  alt={post.title}
                />
              </div>
              <h3>{post.title}</h3>
              <a href={post.link} target="_blank" rel="noopener noreferrer" className="learn-more">Learn more →</a>
            </div>
          ))}
        </div>
        <a href="/writing" className="view-all-button">View All Writings</a>
      </div>
      {/* <FAQ /> */}
      <Footer isLandingPage={true} />
    </div>
  );
};

export default LandingPage;