import React, { useState } from 'react';

const FileUploadSection = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('');
    // const [uploadResults, setUploadResults] = useState([]);
    const [fileDetails, setFileDetails] = useState(null);

    const maxFileSize = 20 * 1024 * 1024; // 20 MB

    // const handleFileChange = (e) => {
    //     const files = Array.from(e.target.files);
    //     const oversized = files.some(file => file.size > maxFileSize);

    //     if (oversized) {
    //         setUploadStatus('File size limit exceeded. Please select files up to 20MB.');
    //         setSelectedFiles([]);
    //     } else {
    //         setSelectedFiles(files);
    //         setUploadStatus('');
    //     }
    // };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= maxFileSize) {
            setSelectedFile(file);
            setUploadStatus('');
        } else {
            setUploadStatus('File size limit exceeded. Please select a file up to 20MB.');
            setSelectedFile(null);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0]; // Taking only the first file

        if (file && file.size <= maxFileSize) {
            setSelectedFile(file);
            setUploadStatus('');
        } else {
            setUploadStatus('File size limit exceeded. Please select a file up to 20MB.');
            setSelectedFile(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedFile) {
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        setUploadStatus('Uploading and analyzing file...');

        try {
            const response = await fetch('http://localhost:5000/api/upload', {
                method: 'POST',
                body: formData,
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setFileDetails(data);
            setUploadStatus('File uploaded and analyzed successfully!');
            setSelectedFile(null);

        } catch (error) {
            console.error('Error uploading file:', error);
            setUploadStatus(`Error: ${error.message}`);
        }
    };


    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     if (!selectedFiles.length) {
    //         return;
    //     }

    //     const formData = new FormData();
    //     selectedFiles.forEach(file => {
    //         formData.append('files', file); // Ensure this key matches the backend expectation
    //     });

    //     setUploadStatus('Uploading files...');

    //     try {
    //         const response = await fetch('http://localhost:5000/api/upload', {
    //             method: 'POST',
    //             body: formData,
    //             credentials: 'include', // Ensures cookies are sent if using sessions
    //         });

    //         if (!response.ok) {
    //             throw new Error(`HTTP error! Status: ${response.status}`);
    //         }

    //         const data = await response.json(); // Correctly handle JSON parsing
    //         if (!response.ok) {
    //             throw new Error(data.message || `HTTP error! Status: ${response.status}`);
    //         }

    //         console.log('File upload details:', data);
    //         setUploadStatus(data.every(file => file.status === 'success') ? 'File uploaded successfully!' : 'failed to upload.');
    //         setSelectedFiles([]);
    //         // setUploadResults(data);

    //     } catch (error) {
    //         console.error('Error uploading files:', error);
    //         setUploadStatus(`Error: ${error.message}`);
    //     }
    // };

    // const data = await response.json();
    // console.log('Files uploaded and analyzed successfully:', data);
    // setUploadStatus('Files uploaded and analyzed successfully!');
    // setSelectedFiles([]);

    // // Update upload results
    // setUploadResults(data);

    // Display categories for each file
    // if (Array.isArray(data)) { // Check if data is an array
    //     data.forEach(file => {
    //         console.log(`File: ${file.fileName}`);
    //         console.log(`Category: ${file.category}`);
    //         console.log(`Description: ${file.description}`);
    //         console.log(`File ID: ${file.fileId}`);
    //         console.log(`Google Drive ID: ${file.googleDriveId}`);
    //     });
    // } else {
    //     // Handle the case where data is a single object
    //     console.log(`File: ${data.fileName}`);
    //     console.log(`Category: ${data.category}`);
    //     console.log(`Description: ${data.description}`);
    //     console.log(`File ID: ${data.fileId}`);
    //     console.log(`Google Drive ID: ${data.googleDriveId}`);
    // }
    //         data.forEach(file => {
    //             console.log(`File: ${file.filename}`);
    //             console.log(`Status: ${file.status}`);
    //             if (file.status === 'success') {
    //                 console.log(`Drive File ID: ${file.driveFileId}`);
    //             } else {
    //                 console.log(`Error: ${file.error}`);
    //             }
    //         });
    //     } catch (error) {
    //         console.error('Error uploading and analyzing file:', error);
    //         setUploadStatus(`Error: ${error.message}`);
    //     }
    // };


    return (
        <div className="upload-modal">
            <style>{`
                .upload-modal {
                    width: 600px;
                    padding: 30px;
                    border-radius: 10px;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
                    background-color: white;
                    margin: 50px auto;
                }
                .upload-header {
                    display: grid;
                    justify-content: flex-start;
                    align-items: center;
                }
                .upload-header h1 {
                    font-size: 18px;
                    font-weight: bold;
                    color: #1995AD;
                    margin: 0;
                    padding-bottom: 20px;
                }
                .upload-header p, .upload-content p, .file-preview h4, .file-preview li {
                    font-size: 1rem;
                    color: #1995AD;
                    margin: 0;
                }
                .upload-area {
                    border: 2px dashed #1995AD;
                    margin-top: 20px;
                    border-radius: 10px;
                    padding: 40px;
                    text-align: center;
                    transition: all 0.3s ease;
                    cursor: pointer;
                }
                .upload-area.dragging {
                    border-color: #1995AD;
                }
                .upload-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                .upload-content p {
                    color: #1995AD;
                    font-size: 1rem;
                    margin: 0;
                    padding-bottom: 20px;
                }
                .browse-btn {
                    display: inline-block;
                    padding: 10px 20px;
                    background-color: #1995AD;
                    color: white;
                    border-radius: 5px;
                    cursor: pointer;
                    font-weight: bold;
                    text-decoration: none;
                }
                .file-preview {
                    margin-top: 20px;
                    text-align: left;
                }
                .file-preview p {
                    color: #1995AD;
            }
                .upload-btn {
                    display: block;
                    margin: 20px auto 0;
                    padding: 10px 20px;
                    background-color: #1995AD;
                    color: white;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    font-size: 16px;
                }
                .upload-btn:hover {
                    background-color: #1995AD;
                }
                .upload-status {
                    color: #1995AD;  
                    text-align: center;
                    font-size: 1rem;
                    padding-top: 20px;
                }
                .upload-results li {
                    color: #1995AD;
                }
                .file-details{
                    color: #1995AD;
                }
            `}</style>
            <div className="upload-header">
                <h1>Upload Files for Data Mapping</h1>
                <p>You can upload files like PDFs, images, videos, audios, and Excel sheets.</p>
            </div>
            <form onSubmit={handleSubmit}>
                <div
                    className={`upload-area ${isDragging ? 'dragging' : ''}`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <div className="upload-content">
                        <p>Drag your file here to start uploading</p>
                        <p>OR</p>
                        <label className="browse-btn" htmlFor="file-input">Browse files</label>
                        <input
                            type="file"
                            id="file-input"
                            accept=".pdf,.png,.jpg,.jpeg,.mp4,.mp3,.xlsx,.xls,.csv"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                    </div>
                </div>
                {selectedFile && (
                    <div className="file-preview">
                        <h4>Selected File:</h4>
                        <p>{selectedFile.name}</p>
                    </div>
                )}
                <button type="submit" className="upload-btn">Upload</button>
                {uploadStatus && <p className="upload-status">{uploadStatus}</p>}
            </form>
            {/* {uploadResults.length > 0 && (
                <div className="upload-results">
                    <h4>Upload Results:</h4>
                    <ul>
                        {uploadResults.map((file, index) => (
                            <li key={index}>
                                <strong>{file.fileName}</strong>
                                <br />
                                Category: {file.category}
                                <br />
                                Description: {file.description}
                            </li>
                        ))}
                    </ul>
                </div>
            )} */}

            {/* {uploadResults.length > 0 && (
                <div className="upload-results">
                    <h4>Upload Results:</h4>
                    <ul>
                        {uploadResults.map((file, index) => (
                            <li key={index}>
                                <strong>{file.filename}</strong> - Status: {file.status}
                                {file.status === 'success' ? (
                                    <span> - Drive File ID: {file.driveFileId}</span>
                                ) : (
                                    <span> - Error: {file.error}</span>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )} */}
            {fileDetails && (
                <div className="file-details">
                    <h3>File Details:</h3>
                    <p>File Name: {fileDetails.file_name}</p>
                    <p>Description: {fileDetails.description}</p>
                    <p>Category: {fileDetails.category}</p>
                    <p>Confidence: {(fileDetails.category_confidence * 100).toFixed(2)}%</p>
                </div>
            )}
        </div>
    );
};

export default FileUploadSection;
