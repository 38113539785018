import React, { useState, useEffect } from 'react';
import { FaYoutube, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import projectPhasesImg from '../../../assets/annampage-images/project-phases.png'
import projectTimelineImg from '../../../assets/annampage-images/project-timeline.png'
import phase1Img from '../../../assets/annampage-images/phase1.png'
import phase2Img from '../../../assets/annampage-images/phase2.png'
import phase3Img from '../../../assets/annampage-images/phase3.png'
import './bhojanamPage.css'
import { bhojanamPageData } from '../../../DATA';
import FileUploadSection from './FileUploadSection';

const BhojanamPage = ({ isFixed = true }) => {
    // Images mapping to match data structure
    const phaseImages = {
        phase1Img,
        phase2Img,
        phase3Img
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!isFixed) return;

        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isFixed]);

    const logo = "YUKTII VENTURES";

    const renderLogo = () => {
        const [firstWord, secondWord] = logo.split(' ');
        return (
            <>
                <div className="p-logo-text">
                    {firstWord.split('').map((char, index) =>
                        char.toUpperCase() === 'U' ?
                            <span key={index} className="p-orange-u">{char}</span> :
                            char
                    )}
                </div>
                <div className="p-logo-subtext">{secondWord}</div>
            </>
        );
    };

    return (
        <div className="bhojanam-page">
            {/* <h2 className="project-title">{bhojanamPageData.projectInfo.title}</h2> */}

            <nav className={`b-navbar ${isFixed && isScrolled ? 'b-navbar-scrolled' : ''} ${isFixed ? 'b-navbar-fixed' : ''}`}>
                <a href="/" className="b-navbar-logo">
                    {renderLogo()}
                </a>
                <div className="b-navbar-center">
                    {bhojanamPageData.projectInfo.title}
                </div>
            </nav>

            <p className="project-description">
                {bhojanamPageData.projectInfo.description}
            </p>

            <img className='project-phases' src={projectPhasesImg} alt="project-phases" />

            <p className="project-description">
                {bhojanamPageData.projectInfo.overviewDescription}
            </p>

            <img className='project-timeline' src={projectTimelineImg} alt="project-timeline" />

            <section className="phase-container">
                {bhojanamPageData.phases.map((phase, index) => (
                    <div key={index} className="phase" style={{
                        flexDirection: index % 2 === 0 ? 'row' : 'row-reverse'
                    }}>
                        <div className='phase-description'>
                            <h2>{phase.title}</h2>
                            <ul>
                                {phase.description.map((item, itemIndex) => (
                                    <li key={itemIndex}>{item}</li>
                                ))}
                            </ul>
                        </div>
                        <div className='phase-img'>
                            <img
                                src={phaseImages[phase.image]}
                                alt={`${phase.title} Image`}
                            />
                        </div>
                    </div>
                ))}
            </section>

            {/* Keywords Section - Added Before Footer */}
            <div className="keywords-section">
                <h3 className="keywords-title">Key Focus Areas</h3>
                <div className="keywords-container">
                    {bhojanamPageData.projectInfo.keywords.map((keyword, index) => (
                        <span key={index} className="keyword-tag">
                            {keyword}
                        </span>
                    ))}
                </div>
            </div>

            <FileUploadSection />

            <footer className="b-footer">
                <div className="b-footer-content">
                    <p>{bhojanamPageData.footer.copyright}</p>
                    <div className="b-social-icons">
                        <a href="https://www.linkedin.com/company/yuktii-ventures/" aria-label="LinkedIn" target="_blank"><FaLinkedin size={32} /></a>
                        <a href="https://x.com/Yuktii_Ventures" aria-label="Twitter" target="_blank"><FaXTwitter size={32} /></a>
                        <a href="https://youtube.com/@yuktii_ventures" aria-label="Youtube" target="_blank"><FaYoutube size={32} /></a>
                        <a href="https://www.instagram.com/yuktii.ventures" aria-label="Instagram" target="_blank"><FaInstagram size={32} /></a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default BhojanamPage;